import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import './App.scss';

import Main from './pages/main/Main'


const router = createBrowserRouter([
  { path: '/', element: <Main /> }
])

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;

