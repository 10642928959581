import Image1 from '../static/images/1.jpg'
import Image2 from '../static/images/2.jpg'
import Image3 from '../static/images/3.jpg'
import Image4 from '../static/images/4.jpg'


export const scenes = [
    {
        id: 'scene_1',
        configs: [
            {
                "config": {
                    "wall_1": "mat_1",
                    "wall_2": "mat_1"
                },
                "panorama": Image1,
            },
            {
                "config": {
                    "wall_1": "mat_2",
                    "wall_2": "mat_2"
                },
                "panorama": Image2
            },
            {
                "config": {
                    "wall_1": "mat_1",
                    "wall_2": "mat_2"
                },
                "panorama": Image3
            },
            {
                "config": {
                    "wall_1": "mat_2",
                    "wall_2": "mat_1"
                },
                "panorama": Image4
            }
        ],
        walls: [
            {
                "id": "wall_1",
                "hotspots": [
                    {
                        "pitch": 5,
                        "yaw": 5,
                    },
                    {
                        "pitch": 3,
                        "yaw": -148,
                    },
                ],
                "types" : [
                    "mat_1",
                    "mat_2"
                ],
                "isScene": false
            },
            {
                "id": "wall_2",
                "hotspots": [
                    {
                        "pitch": 5,
                        "yaw": 54,
                    },
                    {
                        "pitch": 5,
                        "yaw": 125,
                    },
                ],
                "types" : [
                    "mat_1",
                    "mat_2"
                ],
                "isScene": false
            }
        ]

    }
]