import React, { useEffect, useState } from "react";
import 'pannellum';

import './Main.scss'

import { scenes } from "../../data/scenes";


function Main() {

    const [currentScene, setCurrentScene] = useState(scenes[0])
    const [currentConfig, setCurrentConfig] = useState(currentScene.configs[0].config)


    function formatSceneHotspots(walls, config) {
        let frmttdHotspots = []
        for (let wall of walls) {
            if (wall.isScene) {
                // Will be soon

            } else {
                let currentWallType = config[wall.id]
                let nextWallTypeIndex = wall.types.findIndex(type => type === currentWallType) + 1
                if (nextWallTypeIndex > wall.types.length-1) {
                    nextWallTypeIndex = 0 
                }

                let nextWallType = wall.types[nextWallTypeIndex]
                let nextConfig = { ...config }
                nextConfig[wall.id] = nextWallType

                for (let hotspot of wall.hotspots) {
                    frmttdHotspots.push({
                        "pitch": hotspot.pitch,
                        "yaw": hotspot.yaw,
                        'targetYaw': 'same',
                        'targetPitch': 'same',
                        'targetHfov': 'same',
                        "type": "scene",
                        "sceneId": JSON.stringify(nextConfig) + ';' + currentScene.id,
                        // "text": wall.id
                        "text": 'Change',
                        "cssClass": "hotspot"
                    })
                }
            }
        }

        return frmttdHotspots
    }

    function formatScenesList(scenes) {
        let frmttdScenes = {}
        for (let scene of scenes) {
            for (let config of scene.configs) {
                let frmttdConfig = {...config}
                frmttdConfig["hotSpots"] = formatSceneHotspots(scene.walls, config.config)
                frmttdScenes[JSON.stringify(config.config) + ';' + scene.id] = frmttdConfig
            }
        }
        console.log(frmttdScenes)
        return frmttdScenes
    }

    useEffect(() => {

        const frmttdScenes = formatScenesList(scenes)
        
        window.pannellum.viewer('panorama', {   

            'yaw': 30,
            'autoLoad': true,
            'showControls': false, 
            "title": "Sajco Demo",
            "author": "VisEngine",

            "default": {
                "firstScene": JSON.stringify(currentConfig) + ';' + currentScene.id,
                "sceneFadeDuration": 100
            },
            "scenes": frmttdScenes
        });
    }, [])
    

    return (
        <div className="main">
            <div id="panorama"></div>
        </div>
    )
}

export default Main